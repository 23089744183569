<template>
  <div>
    <b-card-group deck>
      <b-card header-tag="header" footer-tag="footer">
        <template v-slot:header>
          <h6 class="mb-0">Tạo mới</h6>
        </template>
        <b-row>
          <b-col sm="3">
            <b-form-group label="Chọn loại phí (*)">
              <b-form-select
                v-model="input.feeType"
                :options="options.feeType"
              ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col sm="3">
            <b-form-group label="Nhóm đối tượng thu/ trả phí (*)">
              <b-form-select
                v-model="input.groupObject"
                :options="options.groupObject"
                value-field="code"
                text-field="name"
              >
                <b-form-select-option :value="null"
                  >Tất cả
                </b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col v-if="!this.isset.service" sm="3">
            <b-form-group label="Đối tượng (*)">
              <b-form-select
                v-model="input.object"
                :options="options.object"
                value-field="code"
                text-field="name"
              >
                <b-form-select-option :value="null"
                  >Tất cả
                </b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col
            v-if="!this.isset.service && this.options.serviceType.length > 0"
            sm="3"
          >
            <b-form-group label="Loại dịch vụ">
              <b-form-select
                v-model="input.serviceType"
                :options="options.serviceType"
                value-field="code"
                text-field="name"
              >
                <b-form-select-option :value="null"
                  >Tất cả
                </b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col v-if="this.isset.service" sm="3">
            <b-form-group label="Nhà cung cấp">
              <b-form-select
                v-model="input.object"
                :options="options.object"
                value-field="code"
                text-field="name"
              >
                <b-form-select-option :value="null"
                  >Tất cả
                </b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col v-if="this.isset.productVnpay">
            <b-form-group label="Danh sách ID mã MCC">
              <b-form-input
                v-model="input.product"
                placeholder="ID mã MCC cách nhau bởi dấu ,"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col v-if="this.isset.service" sm="3">
            <b-form-group label="Loại dịch vụ">
              <b-form-select
                v-model="input.serviceType"
                :options="options.serviceType"
                value-field="code"
                text-field="name"
              >
                <b-form-select-option :value="null"
                  >Tất cả
                </b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col v-if="this.input.object === 'TCB' && this.input.serviceType ==='DEPOSIT'" sm="3">
            <b-form-group label="Loại liên kết">
              <b-form-select
                      v-model="input.actionType"
                      :options="options.actionType"
              >
                <b-form-select-option :value="null"
                >Tất cả
                </b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col v-if="this.isset.service || this.isset.customerWallet" sm="3">
            <b-form-group label="Dịch vụ">
              <multiselect
                v-model="input.service"
                :options="options.service"
                :multiple="true"
                label="name"
                track-by="id"
              ></multiselect>
              <!--              <b-form-select-->
              <!--                v-model="input.service"-->
              <!--                :options="options.service"-->
              <!--                value-field="id"-->
              <!--                text-field="name"-->
              <!--              >-->
              <!--              </b-form-select>-->
            </b-form-group>
          </b-col>
          <b-col v-if="this.isset.service || this.isset.customerWallet" sm="6">
            <b-row>
              <b-col>
                <b-form-group label="Sản phẩm" v-if="!isset.listProduct">
                  <multiselect
                    v-model="input.product"
                    :options="options.product"
                    :multiple="true"
                    label="name"
                    track-by="id"
                  ></multiselect>
                </b-form-group>
                <b-form-group label="Danh sách ID sản phẩm" v-else>
                  <b-form-input
                    v-model="input.product"
                    placeholder="ID sản phẩm cách nhau bởi dấu ,"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-checkbox
                  v-model="isset.listProduct"
                  name="checkbox-1"
                  v-bind:value="1"
                  v-bind:unchecked-value="0"
                >
                  Nhập sản phẩm theo danh sách
                </b-form-checkbox>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card>
    </b-card-group>

    <!--// Hiện thị config phí-->
    <b-card-group deck>
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <b-form-group label="Tính phí theo (*)">
              <b-form-select
                v-model="input.chargeFee"
                :options="options.chargeFee"
              ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group v-if="isset.gmv" label="Tính phí theo tổng GMV vào">
              <b-form-select
                v-model="input.fee.gmvOn"
                :options="options.fee.gmvOn"
                :selected="options.fee.gmvOn[0]"
              ></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Loại giá trị tính phí (*)">
              <b-form-select
                v-model="input.fee.unit"
                :options="options.fee.unit"
              ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col></b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Phương thức tính phí">
              <b-form-select
                v-model="input.fee.structure"
                :options="optionsStructure"
              ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col v-if="this.isset.flat">
            <b-form-group label="">
              <label for="pName">Giá trị phí ({{ this.unitText }})</label>
              <b-form-input
                v-model="input.fee.value"
                type="number"
                min="0"
                placeholder="0"
                @keydown="handleKeyDown"
              ></b-form-input>
              <p class="number-mini">
                Giá trị: {{ numberFormat(parseFloat(this.input.fee.value)) }}
              </p>
            </b-form-group>
          </b-col>
          <b-col v-if="this.isset.flat">
            <b-form-group label="Cộng thêm (VND)">
              <b-form-input
                v-model="input.fee.plus"
                type="number"
                min="0"
                step="1"
                placeholder="0"
                @keydown="handleKeyDown"
              ></b-form-input>
              <p class="number-mini">
                Giá trị: {{ numberFormat(parseFloat(this.input.fee.plus)) }}
              </p>
            </b-form-group>
          </b-col>
          <!--<b-col v-if="!this.isset.flat">-->
            <!--<b-form-group label="Phương pháp tính">-->
              <!--<b-form-select-->
                <!--v-model="input.fee.progressive"-->
                <!--:options="options.fee.progressive"-->
              <!--&gt;</b-form-select>-->
            <!--</b-form-group>-->
          <!--</b-col>-->
          <b-col v-if="!this.isset.flat"></b-col>
        </b-row>
        <span v-if="!this.isset.flat">
          <b>Cài đặt lũy tiến &nbsp; &nbsp;</b>
          <br />
          <b-row>
            <b-col><span>Từ</span></b-col>
            <b-col><span>Đến</span></b-col>
            <b-col><span>Giá trị phí</span></b-col>
            <b-col></b-col>
          </b-row>

          <b-row v-for="(item, index) in input.fee.dataSetting" :key="index">
            <b-col>
              <b-form-group>
                <b-form-input
                  v-model="item.min"
                  type="number"
                  min="0"
                  placeholder="0"
                  @keydown="handleKeyDown"
                ></b-form-input>
                <p class="number-mini">
                  Giá trị: {{ numberFormat(parseFloat(item.min)) }}
                </p>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group>
                <b-form-input
                  v-model="item.max"
                  type="number"
                  min="0"
                  placeholder="0"
                  @keydown="handleKeyDown"
                ></b-form-input>
                <p class="number-mini">
                  Giá trị: {{ numberFormat(parseFloat(item.max)) }}
                </p>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group>
                <b-form-input
                  v-model="item.value"
                  type="number"
                  min="0"
                  placeholder="0"
                  @keydown="handleKeyDown"
                ></b-form-input>
                <p class="number-mini">
                  Giá trị: {{ numberFormat(parseFloat(item.value)) }}
                </p>
              </b-form-group>
            </b-col>
            <b-col>
              <b-button
                v-if="input.fee.dataSetting.length > 1"
                variant="outline-danger"
                size="sm"
                @click="deleteFeeProgressive(index)"
              >
                <b-icon icon="x-lg"></b-icon>
              </b-button>
              &nbsp;
              <b-button
                v-if="index + 1 === input.fee.dataSetting.length"
                variant="outline-primary"
                size="sm"
                @click="addFeeProgressive()"
              >
                <b-icon icon="plus-lg"></b-icon>
              </b-button>
            </b-col>
          </b-row>
        </span>
        <b-row>
          <b-col>
            <b-form-group>
              <b-form-checkbox
                v-model="input.free.isset"
                v-bind:value="1"
                v-bind:unchecked-value="0"
              >
                Miễn phí với
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
        <br />
        <b-row v-if="input.free.isset">
          <b-col>
            <b-row>
              <b-col>
                <b-form-group
                  label="Số giao dịch trong tháng <="
                  label-cols-lg="3"
                >
                  <b-form-input
                    v-model="input.free.data.numberTransaction"
                    placeholder="Giá trị"
                    type="number"
                    min="0"
                    @keydown="handleKeyDown"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group label="">
                  <b-form-select
                    v-model="input.free.data.condition"
                    :options="options.free.data.condition"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col></b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group label="GMV <=" label-cols-lg="3">
                  <b-form-input
                    v-model="input.free.data.gmv"
                    placeholder="0"
                    type="number"
                    min="0"
                    @keydown="handleKeyDown"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col></b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Giá trị tối thiểu">
              <b-form-input
                v-model="input.minimumValue"
                type="number"
                min="0"
                placeholder="0"
                @keydown="handleKeyDown"
              ></b-form-input>
              <p class="number-mini">
                Giá trị: {{ numberFormat(parseFloat(this.input.minimumValue)) }}
              </p>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Phí duy trì (nếu có)">
              <b-form-input
                v-model="input.maintainValue"
                type="number"
                min="0"
                placeholder="0"
                @keydown="handleKeyDown"
              ></b-form-input>
              <p class="number-mini">
                Giá trị:
                {{ numberFormat(parseFloat(this.input.maintainValue)) }}
              </p>
            </b-form-group>
          </b-col>
          <b-col>
            <b-row>
              <b-col
                ><b-form-group>
                  <b-form-checkbox
                    id="checkbox-2"
                    v-model="isset.vat"
                    v-bind:value="1"
                    v-bind:unchecked-value="0"
                  >
                    Tính phí VAT
                  </b-form-checkbox>
                </b-form-group></b-col
              >
              <b-col
                ><b-form-group label="% VAT" v-if="isset.vat">
                  <b-form-input
                    v-model="input.vat"
                    type="number"
                    min="1"
                    placeholder="0"
                    @keydown="handleKeyDown"
                  ></b-form-input> </b-form-group
              ></b-col>
            </b-row>
          </b-col>
          <b-col> </b-col>
        </b-row>
      </b-card>
    </b-card-group>
    <b-card-group>
      <b-card>
        <b-row>
          <b-col>
            <b-form-group label="Từ ngày(*):">
              <date-picker
                v-model="input.from"
                type="datetime"
                placeholder="Chọn thời gian"
                :input-attr="{ required: 'true', valid_at: '' }"
                format="YYYY-MM-DD HH:mm:ss"
                value-type="format"
              ></date-picker>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Đến ngày:">
              <date-picker
                v-model="input.to"
                type="datetime"
                placeholder="Chọn thời gian"
                :input-attr="{ required: 'true', valid_at: '' }"
                format="YYYY-MM-DD HH:mm:ss"
                value-type="format"
              ></date-picker>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group>
              <b-form-checkbox
                id="checkbox-1"
                v-model="input.is_active"
                v-bind:value="1"
                v-bind:unchecked-value="0"
              >
                Kích hoạt
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col class="text-center">
            <b-button class="mr-2" variant="outline-primary" @click="store()"
              >Lưu
            </b-button>
            <b-button class="mr-2" variant="outline-danger" @click="cancel()"
              >Hủy
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </b-card-group>
  </div>
</template>

<script>
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Notifications from "vue-notification";
import Common from "@/core/mixins/common";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Vue from "vue";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import Multiselect from "vue-multiselect";

Vue.component("multiselect", Multiselect);
const CmsRepository = RepositoryFactory.get("cms");
Vue.use(Notifications);
export default {
  mixins: [Common],
  components: { DatePicker },
  data() {
    return {
      isset: {
        listProduct: false,
        free: false,
        vat: false,
        service: false,
        gmv: true,
        flat: true,
        customerWallet: false,
        productVnpay: false,
      },
      input: {
        ladder: true,
        feeType: "RECEIVED",
        groupObject: null,
        object: null,
        actionType: null,
        serviceType: null,
        service: null,
        product: null,
        chargeFee: "GMV",
        chargeCycle: "MONTHLY",
        fee: {
          structure: "FLAT",
          charge: null,
          gmvOn: "MONTHLY",
          progressive: null,
          dataSetting: [
            {
              min: null,
              max: null,
              value: null,
            },
          ],
          value: null,
          unit: "PERCENT",
          plus: null,
        },
        free: {
          isset: null,
          data: {
            numberTransaction: null,
            gmv: null,
            condition: "AND",
          },
        },
        minimumValue: null,
        maintainValue: null,
        issetVat: null,
        vat: null,
        from: this.getCurrentDateTime(),
        to: this.getYesterDateTime(),
        priority: null,
        is_active: 0,
      },
      options: {
        feeType: [
          { value: "RECEIVED", text: "Phí thu" },
          { value: "EXPENSE", text: "Phí Trả" },
        ],
        actionType: [
          { value: "CARD", text: "CARD"},
          { value: "FMB", text: "FMB"},
        ],
        groupObject: [],
        object: [],
        serviceType: [],
        service: [{ id: null, name: "ALL" }],
        product: [{ id: null, name: "ALL" }],
        chargeFee: [
          { value: "VALUE_TRANSACTION", text: "Giá trị từng giao dịch" },
          { value: "GMV", text: "Tổng GMV" },
          { value: "PRICE", text: "Mệnh giá" },
        ],
        fee: {
          structure: [
            { value: "FLAT", text: "Phẳng" },
          ],
          structureGMV: [
            { value: "FLAT", text: "Phẳng" },
            { value: "LADDER", text: "Bậc thang - GMV đạt mức nào thì tất cả giao dịch đều tính theo mức đó" },
          ],
          progressive: [
            { value: "GMV", text: "GMV" },
            // { value: "TRANSACTION", text: "Số lượng giao dịch" },
          ],
          gmvOn: [
            // { value: "DATE", text: "Cuối ngày" },
            // { value: "WEEKLY", text: "Cuối tuần" },
            { value: "MONTHLY", text: "Cuối tháng" },
            // { value: "QUARTER", text: "Cuối quý" },
            // { value: "YEAR", text: "Cuối năm" },
          ],
          unit: [
            { value: "PERCENT", text: "Phần trăm" },
            { value: "DIRECT", text: "Cố định" },
          ],
        },
        free: {
          data: {
            condition: [
              { value: "AND", text: "AND" },
              { value: "OR", text: "OR" },
            ],
          },
        },
        priority: [],
      },
      listAllConfigFee: null,
      unitText: "%",
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Config chi phí", route: "fee" },
    ]);
  },
  computed: {
    optionsStructure() {
      if (this.input.ladder) {
        return this.options.fee.structureGMV;
      } else {
        return this.options.fee.structure;
      }
    }
  },
  methods: {
    cancel() {
      return this.$router.push({ name: "fee" });
    },
    addFeeProgressive() {
      let item = {
        min: 0,
        max: 0,
        value: 0,
      };
      if (this.input.fee.dataSetting.length >= 10) {
        return;
      }
      this.input.fee.dataSetting.push(item);
    },
    deleteFeeProgressive(key) {
      this.input.fee.dataSetting.splice(key, 1);
    },
    store() {
      let params = this.input;
      if (!this.validateStoreForm(params, this.isset)) {
        return;
      } else {
        params = this.convertStoreForm(params);
        CmsRepository.storeFee(params)
          .then((response) => {
            if (response.data.error_code) {
              this.notifyAlert("warn", response.data.message);
              return false;
            } else {
              this.notifyAlert("success", response.data.message);
              return this.$router.push({ name: "fee" });
            }
          })
          .catch(() => {
            alert("Có lỗi xảy ra");
          });
      }
    },

    convertStoreForm(params) {
      if (Array.isArray(params.product)) {
        let listProduct = "";
        params.product.forEach((item) => {
          listProduct += item.id + ",";
        });
        params.product = listProduct;
      }
      if (Array.isArray(params.service)) {
        let listService = "";
        params.service.forEach((item) => {
          listService += item.id + ",";
        });
        params.service = listService;
      }
      if (!this.isset.vat) {
        params.vat = null;
      }
      if (!params.free.isset) {
        params.free.data.numberTransaction =
          params.free.data.condition =
          params.free.data.gmv =
            null;
      }
      return params;
    },
    async listService(params) {
      this.$bus.$emit("show-loading", true);
      const response = await CmsRepository.listServiceFee(params);
      this.$bus.$emit("show-loading", false);
      return response?.data?.data;
    },
    async listConfigService() {
      this.$bus.$emit("show-loading", true);
      let params = {};
      const response = await CmsRepository.listServiceConfigFee(params);
      this.listAllConfigFee = response?.data?.data;
      this.$bus.$emit("show-loading", false);
      // CmsRepository.listServiceConfigFee(params)
      //   .then((response) => {
      //     if (!response.data.data.error_code) {
      //       this.listAllConfigFee = response.data.data;
      //     }
      //     this.$bus.$emit("show-loading", false);
      //   })
      //   .catch(() => {
      //     alert("Có lỗi xảy ra");
      //   });
    },
    async setObjectOption() {
      this.options.groupObject = [];
      this.options.groupObject = this.listAllConfigFee[this.input.feeType];
    },
    setTransactionOrService() {
      this.options.object = [];
      this.listAllConfigFee[this.input.feeType] = this.formatArrayKeyValue(
        this.listAllConfigFee[this.input.feeType]
      );
      if (this.input.groupObject === "SERVICE_PROVIDER") {
        this.isset.service = true;
        if (
          typeof this.listAllConfigFee[this.input.feeType][
            this.input.groupObject
          ].data !== "undefined"
        ) {
          this.listAllConfigFee[this.input.feeType] = this.formatArrayKeyValue(
            this.listAllConfigFee[this.input.feeType]
          );
          this.options.object =
            this.listAllConfigFee[this.input.feeType][
              this.input.groupObject
            ].data;
        }
      } else {
        this.isset.service = false;
        if (
          typeof this.listAllConfigFee[this.input.feeType][
            this.input.groupObject
          ].data !== "undefined"
        ) {
          this.options.object =
            this.listAllConfigFee[this.input.feeType][
              this.input.groupObject
            ].data;
        }
      }
    },
    setListActionOrServiceType() {
      this.options.serviceType = [];
      this.listAllConfigFee[this.input.feeType][this.input.groupObject].data =
        this.formatArrayKeyValue(
          this.listAllConfigFee[this.input.feeType][this.input.groupObject].data
        );
      if (
        typeof this.listAllConfigFee[this.input.feeType][this.input.groupObject]
          .data[this.input.object].data !== "undefined"
      ) {
        this.options.serviceType =
          this.listAllConfigFee[this.input.feeType][
            this.input.groupObject
          ].data[this.input.object].data;
      }
      if (
        this.listAllConfigFee[this.input.feeType][this.input.groupObject].data[
          this.input.object
        ].code === "CUSTOMER_WALLET"
      ) {
        this.options.serviceType =
          this.listAllConfigFee[this.input.feeType][
            this.input.groupObject
          ].data[this.input.object].data;
        this.isset.customerWallet = true;
      } else {
        this.isset.customerWallet = false;
      }
    },
    setListServiceType() {
      this.options.serviceType = [];
      this.listAllConfigFee[this.input.feeType][this.input.groupObject].data =
        this.formatArrayKeyValue(
          this.listAllConfigFee[this.input.feeType][this.input.groupObject].data
        );
      if (
        typeof this.listAllConfigFee[this.input.feeType][this.input.groupObject]
          .data[this.input.object].data !== "undefined"
      ) {
        this.options.serviceType =
          this.listAllConfigFee[this.input.feeType][
            this.input.groupObject
          ].data[this.input.object].data;
      }
    },
    validateStoreForm(params, isset) {
      if (params.groupObject === "" || params.groupObject === null) {
        this.notifyAlert("warn", "Nhóm đối tượng không được để trống");
        return false;
      }
      if (params.object === "" || params.object === null) {
        this.notifyAlert("warn", "Đối tượng không được để trống");
        return false;
      }
      if (params.vat === 0 && isset.vat) {
        this.notifyAlert("warn", "Vat không được = 0");
        return false;
      }
      if (params.from == null) {
        this.notifyAlert("warn", "Ngày bắt đầu không được bỏ trống");
        return false;
      }
      if ((params.to != null || params.to != "") && params.from > params.to) {
        this.notifyAlert("warn", "Ngày bắt đầu phải nhỏ hơn ngày kết thúc");
        return false;
      }

      if (Array.isArray(params.fee.dataSetting)) {
        params.fee.dataSetting.forEach((item) => {
          if (item.min > item.max) {
            this.notifyAlert("warn", "Giá trị lũy tiến phải min < max");
            return false;
          }
        });
      }
      return true;
    },
  },

  async created() {
    await this.listConfigService();
    await this.setObjectOption();
  },
  watch: {
    "input.feeType"() {
      this.input.groupObject = null;
      this.setObjectOption();
    },
    "input.groupObject"() {
      this.input.object = null;
      this.input.serviceType = null;
      this.input.product = null;
      this.input.product = null;
      this.setTransactionOrService();
    },
    "input.object"() {
      if (this.input.object === "SERVICE_PROVIDER") {
        this.input.serviceType = null;
        this.setListServiceType();
      } else {
        this.setListActionOrServiceType();
      }
    },
    async "input.serviceType"() {
      this.input.service = null;
      this.input.product = null;
      this.options.service = [];
      if (
        this.input.groupObject === "SERVICE_PROVIDER" ||
        this.input.object === "CUSTOMER_WALLET" ||
        this.input.object === "DAGORAS"
      ) {
        let params = {
          type: "SERVICE",
          service_type: this.input.serviceType,
        };
        this.options.service = this.options.service.concat(
          await this.listService(params)
        );
      }
      if (this.input.object === "DAGORAS") {
        this.isset.service = true;
      }
      if (
        this.input.groupObject === "PARTNER" &&
        this.input.object === "VNPAY"
      ) {
        this.isset.productVnpay = true;
      }
      if (
        this.input.groupObject !== "PARTNER" &&
        this.input.object !== "VNPAY"
      ) {
        this.isset.productVnpay = false;
      }
    },
    async "input.service"() {
      this.input.product = null;
      this.input.options = null;
      this.options.product = [];
      let productIds = [];
      this.input.service.forEach((item) => {
        productIds.push(item.id);
      });
      let params = {
        type: "PRODUCT",
        service_id: JSON.stringify(productIds),
      };
      this.options.product = this.options.product.concat(
        await this.listService(params)
      );
    },
    "input.chargeFee"() {
      if (this.input.chargeFee == "GMV") {
        this.isset.gmv = true;
        this.input.fee.gmvOn = "MONTHLY";
        this.input.ladder = true;
      } else {
        this.isset.gmv = false;
        this.input.ladder = false;
      }
    },
    "input.fee.structure"() {
      if (
        this.input.fee.structure == "FLAT" ||
        this.input.fee.structure == null
      ) {
        this.isset.flat = true;
      } else {
        this.isset.flat = false;
      }
    },
    "input.fee.unit"() {
      if (this.input.fee.unit == "DIRECT") {
        this.unitText = "VND";
      } else {
        this.unitText = "%";
      }
    },
    "isset.listProduct"() {
      this.input.product = null;
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.mx-datepicker {
  width: 100% !important;
}
.number-mini {
  font-size: 10px;
}
</style>
